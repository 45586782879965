import { getTheme } from "../../../../managers/theme/ThemeManager";
import {
  PADDING,
  MARGIN,
  CUSTOM_FONT,
  CUSTOM_COLOUR,
  CUSTOM_DRIVER_COLOUR
} from "../../../../values/Typography";

const styles = theme => {
  const configTheme = getTheme();

  return {
    parentView: {
      marginTop: "100px",
      paddingRight: theme.spacing.unit * 10,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        paddingRight: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        marginTop: "20px"
      }
    },
    textView: {
      paddingTop: theme.spacing.unit * 2
    },
    imageView: {
      //justify:'center',
      // padding:theme.spacing.unit*4
    },
    footerTitle: {
      color: "white",
      fontFamily: CUSTOM_FONT
    },
    footerText: {
      color: "white",
      fontFamily: CUSTOM_FONT
    },
    media: {
      height: "100%",
      width: "60%",
      color: "white"
    },
    footerDiv: {},
    footerTitlePart: {
      fontWeight: "bold",
      color: "white",
      fontFamily: CUSTOM_FONT
    },
    backgroundImage: {
      height: "100vh"
      // width:'100vw',
    },
    logoContainer: {
      flex: 1,
      paddingRight: theme.spacing.unit
    },
    loginFormSheet: {
      paddingRight: theme.spacing.unit * 10,
      paddingLeft: theme.spacing.unit * 10,
      paddingTop: theme.spacing.unit * 5,
      paddingBottom: theme.spacing.unit * 5,
      flex: 1
    },
    loginTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      marginTop: 20
    },
    userNameStyle: {
      width: "50%",
      height: 100,
      fontFamily: CUSTOM_FONT
    },
    SignupButton: {
      backgroundColor: CUSTOM_DRIVER_COLOUR,
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      width: "100%",
      marginTop: 20,
      height: "5vh",
      color: "black",
      fontWeight: "bold"
    },
    FPbutton: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      fontWeight: "bold"
    },
    SUbutton: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      fontWeight: "bold"
    },
    helperText: {
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      fontWeight: "bold"
    },
    textFeild: {
      fontFamily: CUSTOM_FONT,
      fontSize: 22,
      border: "0px solid",
      outline: "none !important"
    },

    formLink: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 16
    },
    linkContainer: {
      paddingRight: theme.spacing.unit * 3,
      paddingLeft: theme.spacing.unit * 3
    },
    formContainer: {
      padding: theme.spacing.unit,
      paddingRight: theme.spacing.unit * 5,
      paddingLeft: theme.spacing.unit * 5
    },
    bottomLineElement: {
      width: "70%",
      height: "290px",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    fieldContainer: {
      flex: 1
    },
    passwordGrid: {
      marginTop: 20
    }
  };
};

export default styles;
