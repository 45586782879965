import { getTheme } from "../../../../managers/theme/ThemeManager";
import {
  PADDING,
  MARGIN,
  CUSTOM_FONT,
  CUSTOM_COLOUR,
  CUSTOM_DRIVER_COLOUR
} from "../../../../values/Typography";

const styles = theme => {
  const configTheme = getTheme();

  return {
    formPaper: {
      flex: 1,
      paddingBottom: theme.spacing.unit * 5,
      paddingTop: theme.spacing.unit * 2
    },
    loginTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      marginTop: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16
      }
    },
    noDataText: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      marginTop: 20,
      color: "gray"
    },
    SignupButton: {
      backgroundColor: CUSTOM_DRIVER_COLOUR,
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      width: "50%",
      marginTop: 20,
      color: "black",
      fontWeight: "bold"
    },
    formContainer: {
      padding: theme.spacing.unit,
      paddingRight: theme.spacing.unit * 5,
      paddingLeft: theme.spacing.unit * 5
    },
    docBarContainer: {
      flex: 1,
      padding: theme.spacing.unit * 5,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing.unit * 1
      }
    },
    listContainer: {
      //width: '30%',
      height: 200,
      marginLeft: "10%",
      marginRight: "50%"
    }
  };
};

export default styles;
