import React from "react";
import {
    withStyles,
    Typography,
    Button,
    Grid,
    Tabs,
    Tab,
    TextField,
    ButtonBase,
    FormControl,
    Select,
    MenuItem
} from "@material-ui/core";

import { AddDriverCarStyles } from '../../../../styles';
import * as ApiManager from "../../../../../managers/api/ApiManager"
import * as ApiEndPoints from "../../../../../managers/api/ApiEndPoints"
import CustomLoader from '../../../../custom/CustomLoader';
import ServiceSelector from '../../common/ServiceTypeModal'
import { validateLicensePlate, validateColor } from '../../../../../utils/validation';
import CustomSnackbar from "../../../../custom/CustomSnackbar";
import { CUSTOM_FONT } from "../../../../../values/Typography";


const styles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing.unit,
    },
    bootstrapFormLabel: {
      fontSize: 18,
    },
  });

  
class AddDriverCar extends React.Component {

    constructor(props) {
        super(props);
        this.imageRef = React.createRef()
        this.plateRef = React.createRef()
        this.typeRef = React.createRef();
        this.makeRef = React.createRef();
        this.modelRef = React.createRef();
        this.state = {
            showBorder: null,
            value: 0,
            visible: false,
            userSmoker: false,
            smokerSelected: false,
            nonSmokerSelected: true,
            configData: {},
            isLoading: true,
            showFields: false,
            modelData: [],
            showService: false,
            vehicleColor: '',
            vehicleNumber: '',
            vehicleID: '',
            showItems: [],
            serviceIndices: [],
            showSnackbar: false,
            snackbarVariant: "error",
            snakBarMessage: "",
            selectedServices: [],
            existingCarImage: null,
            existingOwnerName: null,
            existingVehicleType: null,
            typeName: "car_type",
            modelName: "car_model",
            makeName: "car_make",
            selectedModel: ""
        };
    }

    componentWillMount() {
        this.getCarType(this.props.areaCode)
        //  this.getCarType(3)
    }

    getComboResult(idVal, driverID, carID) {
        let comboResult = {}
        comboResult["id"] = idVal + 1
        comboResult["driverID"] = driverID
        comboResult["carID"] = carID

        return (comboResult);
    }

    addExistingCar = (inputVal, driverID) => {

        this.setState({ isLoading: true })
        if (this.state.vehicleID  ===  "") {
            this.setState({
                showSnackbar: true,
                snakBarMessage: "Please enter Car Number",
                isLoading: false
            });
        } else {

            ApiManager.callPostApi("AddCar", "driver_add_newCar", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_ADD_EXISTING_CAR, {

                code: this.state.vehicleID,
                driver_id: this.props.driverID

            }).then(res => {
                console.log("existingCarRESPzzzzz" + JSON.stringify(res))
                this.setState({ isLoading: true })
                if (res.result == 1) {
                    this.setState({
                        isLoading: false,
                        existingCarImage: res.data.vehicle_image,
                        existingOwnerName: res.data.owner_driver.first_name,
                        existingVehicleType: res.data.VehicleTypeName
                    })
                    //this.props.callBack(inputVal+1)
                    // this.props.callBack(this.getComboResult(inputVal, res.data.driver_id, res.data.id))

                    // this.setState({ configData: res.data })
                    // setTimeout(() => { this.setState({ showFields: true }) }, 500)
                } else {
                    this.setState({
                        showSnackbar: true,
                        snakBarMessage: res.message,
                        isLoading: false
                    });
                }
                //alert(JSON.stringify(res))
            })
        }
        // this.props.callBack(idVal+1)
    }

    addNewCars() {
        console.log("@$@$@" + JSON.stringify(this.state))
    }

    addNewCar = (inputVal) => {

        this.setState({ isLoading: true })

        if (this.state.carTypeValue) {
            if (this.state.driverCarMake) {
                if (this.state.selectedModel) {
                    if (this.state.selectedServices.length > 0) {
                        let licenseResult = validateLicensePlate(this.state.vehicleNumber)
                        if (licenseResult.status  ===  true) {
                            let colorResult = validateColor(this.state.vehicleColor)
                            if (colorResult.status  ===  true) {
                                if (this.state.carImage) {
                                    if (this.state.numberPlateImage) {

                                        let reqData = {
                                            vehicle_make: this.state.driverCarMake,
                                            driver_id: this.props.driverID,
                                            vehicle_color: this.state.vehicleColor,
                                            service_type: this.state.serviceIndices.toString(),
                                            vehicle_number: this.state.vehicleNumber,
                                            vehicle_model: this.state.selectedModel,
                                            vehicle_type: this.state.carTypeValue,
                                            owner_type: 2,
                                            vehicle_image: "data:image/png;base64," + this.state.carImage,
                                            vehicle_number_plate_image: "data:image/png;base64," + this.state.numberPlateImage,
                                            api_type: 1
                                        }

                                        ApiManager.callPostApi("AddCar", "driver_add_newCar", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_ADDNEWCAR, {
                                            vehicle_make: this.state.driverCarMake,
                                            driver_id: this.props.driverID,
                                            vehicle_color: this.state.vehicleColor,
                                            service_type: this.state.serviceIndices.toString(),
                                            vehicle_number: this.state.vehicleNumber,
                                            vehicle_model: this.state.selectedModel,
                                            vehicle_type: this.state.carTypeValue,
                                            owner_type: 2,
                                            vehicle_image: this.state.carImage,
                                            vehicle_number_plate_image: this.state.numberPlateImage,
                                            api_type: 1

                                        }).then(res => {
                                            console.log("addCarRESPzzzzz" + JSON.stringify(res))
                                            console.log("addNewCarReqqqqq....." + JSON.stringify(reqData))

                                            this.setState({ isLoading: true })
                                            if (res.result == 1) {
                                                this.setState({
                                                    isLoading: false,

                                                }, () => {
                                                    this.props.handleClickOpen("openAddVehicle")
                                                })
                                                //this.props.callBack(inputVal+1)
                                                // this.props.callBack(this.getComboResult(inputVal, res.data.driver_id, res.data.id))

                                                // this.setState({ configData: res.data })
                                                // setTimeout(() => { this.setState({ showFields: true }) }, 500)
                                            } else {
                                                this.setState({
                                                    showSnackbar: true,
                                                    snakBarMessage: res.message,
                                                    isLoading: false
                                                });
                                            }
                                        })

                                    } else {
                                        this.setState({
                                            showSnackbar: true,
                                            snakBarMessage: "Please upload vehicle license plate image",
                                            isLoading: false
                                        });
                                    }

                                } else {
                                    this.setState({
                                        showSnackbar: true,
                                        snakBarMessage: "Please upload vehicle image",
                                        isLoading: false
                                    });
                                }
                            } else {
                                this.setState({
                                    showSnackbar: true,
                                    snakBarMessage: colorResult.error,
                                    isLoading: false
                                });
                            }
                        } else {
                            this.setState({
                                showSnackbar: true,
                                snakBarMessage: licenseResult.error,
                                isLoading: false
                            });
                        }
                    } else {
                        this.setState({
                            showSnackbar: true,
                            snakBarMessage: "Please select services",
                            isLoading: false
                        });
                    }
                } else {
                    this.setState({
                        showSnackbar: true,
                        snakBarMessage: "Please select vehicle model",
                        isLoading: false
                    });
                }
            }
            else {
                this.setState({
                    showSnackbar: true,
                    snakBarMessage: "Please select vehicle make",
                    isLoading: false
                });
            }
        } else {
            this.setState({
                showSnackbar: true,
                snakBarMessage: "Please select vehicle type",
                isLoading: false
            });
        }

        // this.props.callBack(idVal+1)
    }

    handleChange(event) {
        console.log("vvv" + event)
        this.setState({ value: event });
    }

    getValue = (val) => {
        this.props.callBack(val)
    }

    getCarType = (areaID) => {

        this.setState({ isLoading: true })

        ApiManager.callPostApi("AddCar", "driver_show_carTypes", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_GETCARTYPES, {
            area: areaID,

        }).then(res => {
            console.log("hihihih" + JSON.stringify(res))
            this.setState({ isLoading: true })
            if (res.result == 1) {

                this.setState({ isLoading: false })
                this.setState({ configData: res.data })
                setTimeout(() => { this.setState({ showFields: true }) }, 500)
            } else {
                this.setState({
                    isLoading: false,
                    showSnackbar: true,
                    snakBarMessage: res.message,
                });
            }
            //alert(JSON.stringify(res))
        })
    }


    fetchModels = (makeID) => {

        console.log("xxx" + makeID + "," + this.state.carTypeValue)

        this.setState({ isLoading: true })

        ApiManager.callPostApi("AddCar", "driver_show_carModels", ApiEndPoints.APPORIO_TAXI_APIS.FETCH_MODELS, {
            vehicle_make: makeID,
            vehicle_type: this.state.carTypeValue
        }).then(res => {
            // console.log("hihihih"+JSON.stringify(res))
            this.setState({ isLoading: true })
            if (res.result == 1) {
                this.setState({ isLoading: false })
                this.setState({ modelData: res.data })
                console.log("hihihih" + JSON.stringify(res))

            } else {
                this.setState({ isLoading: false })
                alert("Add Vehicle Error: " + res.message)
            }
            //alert(JSON.stringify(res))
        })
    }

    renderExistingCarForm(styles, inputVal) {
        return (

            <div style={{}}>
                <Grid xl container justify="space-between" alignItems="center" style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <TextField
                        label="Enter Vehicle ID"
                        helperText="ie 0000011"
                        InputProps={{
                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                        }}
                        InputLabelProps={{
                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                        }}
                        //className={styles.detailFields}
                        // style={{ height: 100 }}
                        onChange={(evt) => this.setState({ vehicleID: evt.target.value })}
                        margin="normal"
                        variant="outlined"
                    />

                    <Button variant="contained" style={{
                        backgroundColor: '#23669B', fontFamily: CUSTOM_FONT,
                        fontSize: 18, color: 'black',
                        fontWeight: 'bold'
                    }}
                        onClick={() => this.addExistingCar(inputVal)}>
                        Add Vehicle
                     </Button>
                </Grid>

                <Grid xl container justify="space-between" alignItems="center" style={{ paddingRight: '1%', paddingLeft: '1%', marginTop: '2px' }}>
                    <Grid xs={5} item>
                        {
                            (this.state.existingCarImage) ?
                                <img src={ApiEndPoints.TAXI_IMAGE_URL + this.state.existingCarImage} height="100" width="100" /> :
                                null

                        }


                    </Grid>

                    <Grid item xs={7}>
                        <Typography variant="body1" className={styles.ownerDetailStyle}>
                            {this.state.existingOwnerName}
                        </Typography>
                        <Typography variant="body1" className={styles.ownerDetailStyle}>
                            {this.state.existingVehicleType}
                        </Typography>
                    </Grid>
                </Grid>


            </div>
        );
    }

    fetchCarTypeValue(result) {
        //console.log("[[["+result)
        this.setState({ carTypeValue: result })
    }
    fetchCarMakeValue(result) {
        //console.log("]]]"+result)
        this.setState({ driverCarMake: result })
        this.fetchModels(result)
    }

    fetchCarModelValue(result) {
        this.setState({ selectedModel: result })
        // console.log("````"+result)
    }

    fetchServices(result) {
        this.setState({ selectedServices: result })
        console.log("fffff" + JSON.stringify(result))

        this.setState({ showItems: result.map(function (e) { return e.name }) })
        this.setState({ serviceIndices: result.map(function (e) { return e.id }) })

    }

    openExplorer = () => {
        this.imageRef.current.click()
    }

    openExplorerPlate = () => {
        this.plateRef.current.click()
    }

    // --- handle upload ----
    handleCarUpload = (event) => {
        if (event.target.files && event.target.files[0]) {

            let reader = new FileReader
            reader.onload = (e) => {
                console.log("uhuhu>>>" + JSON.stringify(e.target))
                this.setState({
                    carImage: e.target.result
                })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    handlePlateUpload = (event) => {
        if (event.target.files && event.target.files[0]) {

            let reader = new FileReader
            reader.onload = (e) => {
                console.log("uhuhu>>>" + JSON.stringify(e.target))
                this.setState({
                    numberPlateImage: e.target.result
                })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }


    handleSnackBarClose = () => {
        this.setState({
            finalMessage: "",
            showSnackbar: false
        });
    };



    handleCarTypeChange(event, id) {
        console.log("###" + event.target.name)
        // this.props.callback(event.target.value)
        // this.setState({ carTypeValue: result })

        // this.setState({id:event.target.value})
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
            [this.state.makeName]: "",
            [this.state.modelName]: "",
            carTypeValue: event.target.value,
            driverCarMake: "",
            selectedModel: ""
        });
    }

    handleCarMakeChange(event, id) {
        // this.props.callback(event.target.value)
        // this.setState({ carTypeValue: result })

        // this.setState({id:event.target.value})
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
            driverCarMake: event.target.value,
            selectedModel: "",
            [this.state.modelName]: "",
        });
        this.fetchModels(event.target.value)

    }

    handleCarModelChange(event, id) {
        // this.props.callback(event.target.value)
        // this.setState({ carTypeValue: result })

        // this.setState({id:event.target.value})
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
            selectedModel: event.target.value,
        });
    }



    render() {
        const { classes, callback, indexValue, areaCode } = this.props;

        return (
            <div>
                <CustomSnackbar
                    showSnackbar={this.state.showSnackbar}
                    variant={this.state.snackbarVariant}
                    message={this.state.snakBarMessage}
                    onClose={this.handleSnackBarClose}
                />
                {
                    (this.state.configData) ?
                        <ServiceSelector carTypeValue={this.state.carTypeValue} inputList={this.state.configData} visible={this.state.showService} callback={this.fetchServices.bind(this)} onClose={() => this.setState({ showService: false })} /> : null
                }
                <CustomLoader showLoader={this.state.isLoading} />
                <Typography variant="h5" component="h3" className={classes.loginTitle}>
                    ADD YOUR CAR
    </Typography>

                <Tabs
                    value={this.state.value}
                    onChange={(event, value) => this.handleChange(value)}
                    variant="fullWidth"
                    classes={{ root: classes.root, indicator: classes.tabsIndicator }}>
                    <Tab
                        label="New Vehicle"
                        disableRipple
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    />
                    <Tab
                        label="Existing Vehicle"
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        disableRipple
                    />
                </Tabs>

                {(this.state.value === 0 && this.state.showFields) ?
                    <div style={{ margin: '10px', height: '500px', overflowY: 'auto', overflowX: "hidden" }}>
                        <Typography variant="h6" gutterBottom align="left" className={classes.sectionHead}>
                            Create Vehicle
            </Typography>
                        <Grid xl container justify='flex-start' style={{ padding: 10 }}>
                            <Grid container className={classes.optionsContainer}>
                                {/* <MenuListBox fieldLabel="Vehicle Type" listData={this.state.configData.vehicle_type} callback={this.fetchCarTypeValue.bind(this)} indexID={1} /> */}
                                <Grid xl container justify="flex-start" alignItems="center" spacing={16} className={classes.selectContainer}>
                                    <Grid item style={{ flex: 1 }}>
                                        <Typography variant="subtitle" className={classes.detailTitle}>
                                            Vehicle Type
                  </Typography>
                                    </Grid>
                                    <Grid item style={{ flex: 1 }}>

                                        {/* <FormControl className={classes.formControl}>
                  <Select
                    native inputProps={{ style: { width: 100 } }}
                    value={this.state.age}
                    displayEmpty
                   
                    onChange={(evt,id) => this.handleCarTypeChange(evt,id)}
                    className={classes.selectOption}
                    input={<BootstrapInput  ref={this.typeRef} name={this.state.typeName} placeholder="" id="car_type-select" />}>
                    <option value="">None</option>
                  {this.state.configData.vehicle_type.map(row => (
                                          <option value={row.id} className={styles.areaItem}>{row.vehicleTypeName}</option>
                                      ))}
                  </Select>
                </FormControl> */}

                                        <FormControl className={classes.formControl}>
                                            <Select
                                                value={this.state.carTypeValue}
                                                onChange={(evt, id) => this.handleCarTypeChange(evt, id)}
                                                inputProps={{
                                                    name: 'age',
                                                    id: 'age-simple',
                                                }}>

                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {this.state.configData.vehicle_type.map(row => (
                                                    <MenuItem value={row.id} className={styles.areaItem} >{row.vehicleTypeName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* <MenuListBox fieldLabel="Vehicle Make" listData={this.state.configData.vehicle_make} callback={this.fetchCarMakeValue.bind(this)} indexID={2} /> */}
                                <Grid xl container justify="flex-start" alignItems="center" spacing={16} className={classes.selectContainer}>
                                    <Grid item style={{ flex: 1 }}>
                                        <Typography variant="subtitle" className={classes.detailTitle}>
                                            Vehicle Make
                  </Typography>
                                    </Grid>
                                    <Grid item style={{ flex: 1 }}>

                                        <FormControl className={classes.formControl}>
                                            {/* <Select
                    ref={this.makeReft}
                    native inputProps={{ style: { width: 100 } }}
                    value={this.state.age}
                    displayEmpty
                    onChange={(evt,id) => this.handleCarMakeChange(evt,id)}
                    className={classes.selectOption}
                    input={<BootstrapInput name={this.state.makeName} placeholder="" id="car_make-select" />}>

                  <option value="">None</option>
                  {this.state.configData.vehicle_make.map(row => (
                                          <option value={row.id} className={styles.areaItem}>{row.vehicleMakeName}</option>
                                      ))}
                  </Select> */}
                                            <Select
                                                value={this.state.driverCarMake}
                                                onChange={(evt, id) => this.handleCarMakeChange(evt, id)}
                                                inputProps={{
                                                    name: 'age',
                                                    id: 'age-simple',
                                                }}>

                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {this.state.configData.vehicle_make.map(row => (
                                                    <MenuItem value={row.id} className={styles.areaItem} >{row.vehicleMakeName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* <MenuListBox fieldLabel="Vehicle Model" listData={this.state.modelData} callback={this.fetchCarModelValue.bind(this)} indexID={3} /> */}
                                <Grid xl container justify="flex-start" alignItems="center" spacing={16} className={classes.selectContainer}>
                                    <Grid item style={{ flex: 1 }}>
                                        <Typography variant="subtitle" className={classes.detailTitle}>
                                            Vehicle Model
                  </Typography>
                                    </Grid>
                                    <Grid item style={{ flex: 1 }}>

                                        <FormControl className={classes.formControl}>
                                            {/* <Select
                    ref={this.modelRef}
                    native inputProps={{ style: { width: 100 } }}
                    value={this.state.age}
                    displayEmpty
                    onChange={(evt,id) => this.handleCarModelChange(evt,id)}
                    className={classes.selectOption}
                    input={<BootstrapInput name={this.state.modelName} placeholder="" id="car_model-select" />}>
                  <option value="">None</option>
                  {this.state.modelData.map(row => (
                                          <option value={row.id} className={styles.areaItem}>{row.vehicleModelName}</option>
                                      ))}
                  </Select> */}
                                            <Select
                                                value={this.state.selectedModel}
                                                onChange={(evt, id) => this.handleCarModelChange(evt, id)}
                                                inputProps={{
                                                    name: 'age',
                                                    id: 'age-simple',
                                                }}>
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {this.state.modelData.map(row => (
                                                    <MenuItem value={row.id} className={styles.areaItem} >{row.vehicleModelName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                (this.state.showFields && this.state.selectedModel) ?
                                    <Grid container xl justify="space-between" className={classes.detailTitle}>
                                        <Button variant="outlined" className={classes.servicesButton} onClick={() => { this.setState({ showService: true }) }}>
                                            Select Services
                    </Button>

                                        <Typography variant="body1" gutterBottom align="left" className={classes.itemSet}>
                                            {this.state.showItems.join(',')}
                                        </Typography>
                                    </Grid> : null
                            }
                        </Grid>


                        <Typography variant="h6" gutterBottom align="left" className={classes.sectionHead}>
                            Add Vehicle Details
                         </Typography>



                        <Grid container >
                            <Grid md={12} container>
                                <Grid item md={6}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="Enter Vehicle Number"
                                        helperText="e.g SS 88 TA 1234"

                                        className={classes.detailFields}
                                        InputProps={{
                                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                                        }}
                                        onChange={(evt) => this.setState({ vehicleNumber: evt.target.value })}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} align="center" style={{}}>
                                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                                        Upload Vehicle image
                                   </Typography>
                                    <ButtonBase accept="image/*" htmlFor="icon-button-file" id="icon-button-file" focusRipple onClick={() => this.openExplorer()} >
                                        <Grid item className={classes.avatarContainer}>
                                            {
                                                (Boolean(this.state.carImage)) ?
                                                    <img src={this.state.carImage} style={{ width: 70, height: 50 }} />
                                                    :
                                                    <img src='https://image.flaticon.com/icons/svg/55/55283.svg' style={{ width: 70, height: 50 }} />

                                            }
                                        </Grid>
                                    </ButtonBase>
                                    <input type="file" ref={this.imageRef} style={{ width: '0', height: '0', overflow: 'hidden' }} onChange={(event) => this.handleCarUpload(event)}></input>

                                </Grid>
                            </Grid>
                            <Grid md={12} container>
                                <Grid item md={6}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="Enter Vehicle Color"
                                        InputProps={{
                                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                                        }}
                                        className={classes.detailFields}
                                        onChange={(evt) => this.setState({ vehicleColor: evt.target.value })}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} align="center" style={{}}>
                                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                                        Upload Vehicle Number Plate image
                                    </Typography>
                                    <ButtonBase accept="image/*" htmlFor="icon-button-file" id="icon-button-file" focusRipple onClick={() => this.openExplorerPlate()} >
                                        <Grid item className={classes.avatarContainer}>
                                            {
                                                (Boolean(this.state.numberPlateImage)) ?
                                                    <img src={this.state.numberPlateImage} style={{ width: 70, height: 50 }} />
                                                    :
                                                    <img src='https://www.flaticon.com/premium-icon/icons/svg/713/713981.svg' style={{ width: 70, height: 50 }} />
                                            }
                                        </Grid>
                                    </ButtonBase>
                                    <input type="file" ref={this.plateRef} style={{ width: '0', height: '0', overflow: 'hidden' }} onChange={(event) => this.handlePlateUpload(event)}></input>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid md={12} className={classes.addDriverVehicleBtn}>
                            <Button variant="contained"
                                className={classes.AddVehiclebutton}
                                onClick={() => this.addNewCar(indexValue)}>
                                Add Vehicle
                </Button>
                        </Grid>
                    </div>
                    : null}

                {this.state.value === 1 && this.renderExistingCarForm(classes, indexValue)}
            </div>

        );
    }
}

export default withStyles(AddDriverCarStyles)(AddDriverCar);




