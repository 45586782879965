import React, { Component } from "react";
import * as ApiManager from "../../../../managers/api/ApiManager";
import { Redirect, withRouter } from "react-router-dom";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { InputBase, Button } from "@material-ui/core";
import { APPORIO_TAXI_APIS } from "../../../../managers/api/ApiEndPoints";
import CustomLoader from "../../../custom/CustomLoader";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import EventBus from "eventing-bus";
import { CUSTOM_COLOUR } from "../../../../values/Typography";

class LoginComponent extends Component {
  state = {
    faltu: true,
    username: "",
    password: "",
    isLoading: false,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    country_code: this.props.countrySet,
    phonecode: this.props.configData.countries[0].phonecode,
    country_id: "",
    showForm: false,
    userIDHint: null,
    maxNum: this.props.configData.countries[0].maxNumPhone,
    minNum: this.props.configData.countries[0].minNumPhone
  };

  showLoader() {
    if (this.state.isLoading) {
      return <CustomLoader showLoader={this.state.isLoading} />;
    }
  }

  checkSession = () => {
    console.log(
      "Session token",
      SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN)
    );
    if (SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN)) {
      EventBus.publish("SET_HOME_ACTIVE", "1");
      return this.props.history.push("/user_booking");
    }
  };

  executeSpecificDetailsApi = () => {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "Login  Component",
      "Login APi",
      APPORIO_TAXI_APIS.USER_DETAILS,
      {
        player_id: "c372814e-c427-45ee-8c73-e8254c9a4a8a",
        unique_no: Math.random(),
        package_name: "com.appoiro.website"
      }
    )
      .then(res => {
        console.log("xsxsxs" + JSON.stringify(res));
        if (res.result === "1") {
          SessionManager.saveSessionData(
            SessionKeys.USER_DATA,
            JSON.stringify(res.data)
          );

          this.setState({ isLoading: false }, () => {
            this.checkSession();
          });
        } else {
          this.setState({
            showSnackbar: true,
            snakBarMessage: res.message,
            isLoading: false
          });
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };

  handleSnackBarClose = () => {
    this.setState({
      snakBarMessage: "",
      showSnackbar: false
    });
  };

  handleLogin = () => {
    if (this.state.username == "") {
      this.setState({
        showSnackbar: true,
        snakBarMessage: this.props.configData.login.email
          ? LanguageManager.Strings.EMAIL_VALIDATION
          : LanguageManager.Strings.PHONE_VALIDATION,
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else if (
      this.props.configData.login.phone &&
      this.state.username.length < this.state.minNum
    ) {
      this.setState(
        {
          showSnackbar: true,
          snakBarMessage: LanguageManager.Strings.PHONE_MIN_VALIDATION,
          isLoading: false,
          snackbarVariant: "warning"
        },
        console.log("username", this.state.username)
      );
    } else if (this.state.password == "") {
      this.setState({
        showSnackbar: true,
        snakBarMessage: LanguageManager.Strings.PASSWORD_VALIDATION,
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else {
      this.setState({ isLoading: true });
      ApiManager.callPostApi(
        "Login  Component",
        "user deatils APi",
        APPORIO_TAXI_APIS.USER_LOGIN,
        {
          phone: this.props.configData.login.email
            ? this.state.username
            : this.state.phonecode + this.state.username,
          password: this.state.password,
          requested_from: "website"
        }
      )
        .then(res => {
          if (res.result === "1") {
            SessionManager.saveSessionData(
              SessionKeys.ACCESS_TOKEN,
              res.data.access_token
            );
            this.executeSpecificDetailsApi();
          } else {
            this.setState({
              isLoading: false,
              showSnackbar: true,
              snakBarMessage: res.message
            });
          }
        })
        .catch(err => alert(JSON.stringify(err)));
    }
  };

  handleChange = async event => {
    let index = await this.props.configData.countries.findIndex(
      res => res.phonecode === event.target.value
    );

    this.setState({
      phonecode: event.target.value,
      country_id: this.props.configData.countries[index].id,
      maxNum: this.props.configData.countries[index].maxNumPhone
    });
  };

  executeSpecificDetails = () => {};
  render() {
    const { configData } = this.props;
    console.log("state", this.state);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />

        {this.showLoader()}
        <div
          style={{
            padding: "2%",
            display:
              !configData.login.email && !configData.login.phone
                ? "none"
                : "flex",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%"
            }}
          >
            {!configData.login.email ? (
              <Select
                value={this.state.phonecode ? this.state.phonecode : null}
                onChange={this.handleChange}
                inputProps={{
                  name: "age",
                  id: "age-simple"
                }}
              >
                {this.props.configData.countries.map((res, index) => (
                  <MenuItem key={index} value={res.phonecode}>
                    {res.phonecode}
                  </MenuItem>
                ))}
              </Select>
            ) : null}

            <InputBase
              value={this.state.username}
              type={configData.login.email ? "text" : "number"}
              onChange={(event, val) => {
                if (
                  configData.login.phone &&
                  event.target.value.length <= this.state.maxNum
                ) {
                  this.setState({ username: event.target.value });
                } else if (configData.login.email) {
                  this.setState({ username: event.target.value });
                }
              }}
              placeholder={this.state.userIDHint ? this.state.userIDHint : ""}
              style={{
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            />
          </div>
        </div>

        <div
          style={{
            padding: "2%",
            marginTop: "1%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <img
            src="https://png.pngtree.com/svg/20170622/d39689bb9c.png"
            style={{ width: 20, height: 20 }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              marginLeft: 15
            }}
          >
            <InputBase
              type="password"
              onChange={(event, val) => {
                this.setState({ password: event.target.value });
              }}
              placeholder={LanguageManager.Strings.PASSWORD_HINT}
              style={{
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            />
          </div>
        </div>

        <Button
          onClick={() => {
            this.handleLogin();
          }}
          style={{
            margin: "5%",
            backgroundColor: CUSTOM_COLOUR,
            color: "black",
            fontWeight: "bold"
          }}
        >
          {LanguageManager.Strings.LOGIN_BTN}
        </Button>
      </div>
    );
  }
}

export default withRouter(LoginComponent);
