import React, { Component } from "react";
import color from "@material-ui/core/colors/purple";
import PropTypes from "prop-types";

import {
  InputBase,
  TextField,
  withStyles,
  Typography,
  Button,
  Grid,
  Icon,
  Paper,
  ButtonBase,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CardActionArea,
  CircularProgress
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import Checkbox from "@material-ui/core/Checkbox";
import UserLayout from "../common/UserLayout";
import { USER_DATA } from "../../../../managers/session/SessionKeys";
import { getSessionData } from "../../../../managers/session/SessionManager";
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone
} from "../../../../utils/validation";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../managers/api/ApiManager";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import { Redirect } from "react-router-dom";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import { APPORIO_TAXI_APIS } from "../../../../managers/api/ApiEndPoints";
import OtpVerification from "../../user/otpverification/OtpVerification";
import DialogForm from "../../../custom/DialogForm";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import EventBus from "eventing-bus";
import { CUSTOM_COLOUR, CUSTOM_FONT } from "../../../../values/Typography";

const genders = [
  {
    value: 1,
    label: "Male"
  },
  {
    value: 2,
    label: "Female"
  }
];

const smokers = [
  {
    value: 1,
    label: "Smoker"
  },
  {
    value: 2,
    label: "Non Smoker"
  }
];

const styles = theme => ({
  parentContainer: {
    paddingTop: "10%",
    paddingBottom: "15%",
    paddingLeft: "1%",
    paddingRight: "1%",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "rgba(0,0,0,0.8)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
      paddingLeft: "10%",
      paddingRight: "10%"
    }
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: 5
  },
  demo: {
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  formContainer: {
    flex: 1,
    marginTop: "2%"
  },
  defaultImage: {
    objectFit: "cover",
    height: 200,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      height: 100,
      width: 100
    }
  },
  newImage: {
    objectFit: "cover",
    backgroundColor: "silver",
    height: 200,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      height: 100,
      width: 100
    }
  },
  imageBox: {
    backgroundSize: "contain",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "200px",
    height: "250px",
    marginBottom: "15px",
    overflow: "hidden"
    // '& button': {
    // width: '50%',
    // height: '25%',
    // boxShadow: 'none',
    // border: 'none'
    // }
  },
  genderContainer: {
    marginTop: 10,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  genderTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  },
  smokerContainer: {
    marginTop: 10
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing.unit * 2
  },
  bgImage: {
    height: "100%"
  },
  rootMenu: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  areaItem: {
    fontFamily: "Montserrat",
    fontSize: 14
  },

  SignupButton: {
    backgroundColor: CUSTOM_COLOUR,
    fontFamily: CUSTOM_FONT,
    fontSize: 18,
    textTransform: "none",
    width: "60%",
    marginTop: 20,
    height: "70%",
    color: "black",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "9%"
    }
  },

  formAreaContainer: {
    marginLeft: "10%",
    paddingBottom: "1%",
    paddingTop: "1%",
    flex: 2
  },
  iconFields: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  detailFields: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      fontSize: 14
    }
  },
  genderMobileContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex"
    }
  },
  imageContainer: {
    flex: 1,
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "94%",
      margin: "3%",
      alignItems: "center"
    }
  }
});

export class UserProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      userdetails: null,
      image: props.imageUrl ? props.imageUrl : null,
      date: props.date ? props.date : "",
      dateRequired:
        props.dateRequired == undefined || props.dateRequired == true
          ? true
          : false,
      disabled: Boolean(props.disabled) ? true : false,
      snack: {
        open: false,
        variant: "error",
        message: "Please select required fields"
      },
      active: 1,
      expiry: 2,
      isLoading: false,
      maleSelected: true,
      femaleSelected: false,
      smokerSelected: false,
      nonSmokerSelected: true,
      first_name: "",
      last_name: "",
      user_email: "",
      user_image: "",
      user_phone: "",
      gender_type: "",
      smoker_type: "",
      allow_smoke_driver: "",
      showSnackbar: false,
      snackbarVariant: "error",
      snakBarMessage: "",
      newUserImage: null,
      logOutNow: false,
      showForm: false,
      userGender: 1,
      smokerType: 1
    };
  }

  redirectToDashboard = () => {
    if (this.state.logOutNow) {
      return (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      );
    }
  };

  executeSpecificDetailsApi = () => {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "Login  Component",
      "Login APi",
      APPORIO_TAXI_APIS.USER_DETAILS,
      {
        player_id: SessionManager.getSessionData(SessionKeys.PLAYER_ID),
        unique_no: Math.random(),
        package_name: "com.appoiro.website"
      }
    )
      .then(res => {
        console.log("xsxsxs" + JSON.stringify(res));

        if (res.result === "1") {
          SessionManager.saveSessionData(
            SessionKeys.USER_DATA,
            JSON.stringify(res.data)
          );

          setTimeout(() => {
            EventBus.publish("REFRESH_SESSION", "1");
          }, 500);

          this.setState({ isLoading: false });
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };

  saveProfileChanges = idVal => {
    this.setState({ isLoading: true });

    let firstNameResult = validateFirstName(this.state.first_name);
    if (firstNameResult.status == true) {
      let lastNameResult = validateLastName(this.state.last_name);
      if (lastNameResult.status == true) {
        let emailResult = validateEmail(this.state.user_email);
        if (emailResult.status == true) {
          if (this.state.phoneNumber) {
            if (this.state.maleSelected || this.state.femaleSelected) {
              if (this.state.smokerSelected || this.state.nonSmokerSelected) {
                if (this.state.user_image) {
                  ApiManager.callPostApi(
                    "UserProfile",
                    "user editProfile api",
                    ApiEndPoints.APPORIO_TAXI_APIS.USER_PROFILE_EDIT,
                    {
                      api_type: 1,
                      first_name: this.state.first_name,
                      last_name: this.state.last_name,
                      // name:this.state.firstName+" "+this.state.lastName,
                      email: this.state.user_email,
                      phone: this.state.phonecode + this.state.phoneNumber,
                      user_gender: this.state.maleSelected ? 1 : 2,
                      smoker_type: this.state.smokerSelected ? 1 : 2,
                      allow_other_smoker: this.state.driverSmoker
                        ? "Yes"
                        : "No",
                      profile_image: this.state.newUserImage
                        ? this.state.newUserImage
                        : undefined,
                      player_id: "40ae9548-9ddc-4ef0-b77f-84db6f4c4242"
                    }
                  ).then(res => {
                    this.setState({ isLoading: false });
                    if (res.result == 1) {
                      this.setState(
                        {
                          showSnackbar: true,
                          snackbarVariant: "success",
                          snakBarMessage: "Profile Updated Successfully.",
                          isLoading: false
                        }
                        // () => this.refs.userLayout.updateData()
                      );

                      this.executeSpecificDetailsApi();
                    } else {
                      this.setState({
                        snackbarVariant: "error",
                        showSnackbar: true,
                        snakBarMessage: "Signup Error:" + res.message,
                        isLoading: false
                      });
                    }
                  });
                } else {
                  this.setState({
                    showSnackbar: true,
                    snakBarMessage: "Please upload a profile picture",
                    isLoading: false,
                    snackbarVariant: "warning"
                  });
                }
              } else {
                this.setState({
                  showSnackbar: true,
                  snakBarMessage: "Please select if you smoke or not",
                  isLoading: false,
                  snackbarVariant: "warning"
                });
              }
            } else {
              this.setState({
                showSnackbar: true,
                snakBarMessage: "Please select Gender",
                isLoading: false,
                snackbarVariant: "warning"
              });
            }
          } else {
            this.setState({
              showSnackbar: true,
              snakBarMessage: "Please enter phone number",
              isLoading: false,
              snackbarVariant: "warning"
            });
          }
        } else {
          this.setState({
            showSnackbar: true,
            snakBarMessage: emailResult.error,
            isLoading: false,
            snackbarVariant: "warning"
          });
        }
      } else {
        this.setState({
          showSnackbar: true,
          snakBarMessage: lastNameResult.error,
          isLoading: false,
          snackbarVariant: "warning"
        });
      }
    } else {
      this.setState({
        showSnackbar: true,
        snakBarMessage: firstNameResult.error,
        isLoading: false,
        snackbarVariant: "warning"
      });
    }
  };

  componentDidMount() {
    let sessiondata = JSON.parse(getSessionData(USER_DATA));

    this.setState(
      {
        userdetails: sessiondata,
        phonecode: sessiondata.phone_code,
        user_image: ApiEndPoints.TAXI_IMAGE_URL + sessiondata.UserProfileImage,
        first_name: sessiondata.first_name,
        last_name: sessiondata.last_name,
        user_email: sessiondata.email,
        phoneNumber: sessiondata.UserPhone,
        smoker_type: sessiondata.smoker_type,
        gender_type: sessiondata.user_gender,
        allow_smoke_driver: sessiondata.allow_other_smoker
      },
      () => {
        if (this.state.smoker_type === "1") {
          this.setState({ smokerSelected: true, nonSmokerSelected: false });
        } else if (this.state.smoker_type === "2") {
          this.setState({ smokerSelected: false, nonSmokerSelected: true });
        }
        if (this.state.gender_type === "1") {
          this.setState({ maleSelected: true, femaleSelected: false });
        } else if (this.state.gender_type === "2") {
          this.setState({ maleSelected: false, femaleSelected: true });
        }
        if (this.state.allow_smoke_driver === "Yes") {
          this.setState({ driverSmoker: true });
        } else {
          this.setState({ driverSmoker: false });
        }
      }
    );
    console.log("33333" + JSON.stringify(sessiondata));

    this.getCountryCodes();
  }

  getCountryCodes() {
    //  SessionManager.particularKey(SessionKeys.ACCESS_TOKEN);
    // SessionManager.clearSession();
    let sessiondata = JSON.parse(
      SessionManager.getSessionData(SessionKeys.USER_CONFIG)
    );
    this.setState({
      country_code: sessiondata.countries,
      // phonecode: sessiondata.countries[0].phonecode,
      smokerEnable: sessiondata.register.smoker
    });
    console.log("Seion Data========>" + JSON.stringify(sessiondata.countries));
  }

  handleSnackBarClose = () => {
    this.setState({
      snakBarMessage: "",
      showSnackbar: false
    });
  };

  getResponse(result) {
    if (result.result === "1") {
      this.setState({
        showSnackbar: true,
        snackbarVariant: "success",
        snakBarMessage: result.message
      });
    } else {
      this.setState({
        showSnackbar: true,
        snackbarVariant: "error",
        snakBarMessage: result.message
      });
    }
  }

  openExplorer = () => {
    this.imageRef.current.click();
  };

  handleUpload = event => {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({
        user_image: e.target.result,
        newUserImage: e.target.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  handleChange(event) {
    if (event) {
      if (event.target.value == 1) {
        this.setState({ femaleSelected: false, maleSelected: true });
      } else {
        this.setState({ femaleSelected: true, maleSelected: false });
      }
    } else {
      // setSelectedValue(event.target.value);
      // this.setState({setSelectedValue:event.target.value, selectedValue:event.target.value})
      if (this.state.maleSelected) {
        this.setState({ femaleSelected: true, maleSelected: false });
      } else {
        this.setState({ femaleSelected: false, maleSelected: true });
      }
    }
  }

  handleGChange = name => event => {
    console.log("WAWA" + event.target.value);
    this.setState({
      [name]: event.target.value,
      userGender: event.target.value,
      maleSelected: event.target.value == 1 ? true : false
    });
  };

  handleSChange = name => event => {
    console.log("WAWA" + event.target.value);
    this.setState({
      [name]: event.target.value,
      smokerType: event.target.value,
      smokerSelected: event.target.value == 1 ? true : false
    });
  };

  handleSmokerChange(event) {
    if (event) {
      if (event.target.value == 1) {
        this.setState({ smokerSelected: true, nonSmokerSelected: false });
      } else {
        this.setState({ smokerSelected: false, nonSmokerSelected: true });
      }
    } else {
      if (this.state.smokerSelected) {
        this.setState({ nonSmokerSelected: true, smokerSelected: false });
      } else {
        this.setState({ nonSmokerSelected: false, smokerSelected: true });
      }
    }
  }

  handleChanges(event) {
    this.setState({ areaIndex: event.target.value });
    // this.setState({driverArea:this.state.areaData[event.target.value-1].area})
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  }

  getLogoutResponse(result) {
    EventBus.publish("KILL_HOME", "1");

    console.log("~~~~@~~~~", result);
    if (result) {
      console.log("@1@1@1@ ::" + result);
      SessionManager.particularKey(SessionKeys.ACCESS_TOKEN);
      this.setState({ logOutNow: true });

      // this.props.history.replace('/')
      // this.props.history.index = 0;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <UserLayout
        ref={"userLayout"}
        logoutCallBack={this.getLogoutResponse.bind(this)}
      >
        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />
        {this.redirectToDashboard()}
        <DialogForm
          visible={this.state.showForm}
          onOutsideTouch={() => {
            this.setState({ showForm: !this.state.showForm });
          }}
        >
          <OtpVerification
            countrySet={this.state.country_code}
            otpType={1}
            otpCallback={this.getResponse.bind(this)}
            getOtp={(phoneNumber, country_id, phonecode) =>
              this.setState(
                {
                  phoneNumber: phoneNumber,
                  showForm: !this.state.showForm,
                  countryId: country_id,
                  phonecode: this.state.phonecode
                },
                () =>
                  console.log(
                    "!!!!" + phoneNumber + "," + country_id + "," + phonecode
                  )
              )
            }
          />
        </DialogForm>

        <div className={classes.parentContainer}>
          <Paper>
            <Grid
              xl
              container
              className={classes.demo}
              justify="space-around"
              spacing={16}
            >
              <Grid item className={classes.imageContainer}>
                <Button
                  onClick={() => this.openExplorer()}
                  disabled={this.state.disabled}
                >
                  {Boolean(this.state.user_image) ? (
                    <img
                      src={this.state.user_image}
                      className={classes.newImage}
                    />
                  ) : (
                    <img
                      src="https://image.flaticon.com/icons/svg/74/74472.svg"
                      className={classes.defaultImage}
                    />
                  )}
                </Button>
                <input
                  type="file"
                  ref={this.imageRef}
                  style={{
                    display: "none",
                    width: "0",
                    height: "0",
                    overflow: "hidden",
                    border: "1px solid transparent"
                  }}
                  onChange={event => this.handleUpload(event)}
                />
              </Grid>
              <Grid
                container
                direction="column"
                justify="flex-start"
                className={classes.formAreaContainer}
              >
                <TextField
                  id="standard-fname"
                  label={LanguageManager.Strings.FNAME_HINT}
                  className={classes.detailFields}
                  value={this.state.first_name}
                  onChange={event =>
                    this.setState({ first_name: event.target.value })
                  }
                  margin="normal"
                />
                <TextField
                  id="standard-lname"
                  label={LanguageManager.Strings.LNAME_HINT}
                  className={classes.detailFields}
                  value={this.state.last_name}
                  onChange={event =>
                    this.setState({ last_name: event.target.value })
                  }
                  margin="normal"
                />

                <TextField
                  id="standard-email"
                  label={LanguageManager.Strings.EMAIL_HINT}
                  className={classes.detailFields}
                  value={this.state.user_email}
                  onChange={event =>
                    this.setState({ user_email: event.target.value })
                  }
                  margin="normal"
                />

                <CardActionArea
                  onClick={() => {
                    this.setState({ showForm: true });
                  }}
                  style={{
                    //display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "row"
                  }}
                >
                  <Grid container>
                    <TextField
                      id="standard-phone"
                      label={LanguageManager.Strings.PHONE_HINT}
                      className={classes.detailFields}
                      value={this.state.phonecode + this.state.phoneNumber}
                      margin="normal"
                    />
                  </Grid>
                </CardActionArea>

                <Grid
                  xl
                  container
                  justify="flex-start"
                  alignItems="center"
                  className={classes.genderContainer}
                >
                  <Typography variant="h6" className={classes.genderTitle}>
                    {LanguageManager.Strings.GENDER_LABEL}
                  </Typography>

                  <Radio
                    style={{ marginLeft: "2%" }}
                    checked={this.state.maleSelected}
                    onChange={() => this.handleChange()}
                    value="m"
                    color="primary"
                    name="radio-male"
                    aria-label="M"
                  />
                  <Typography variant="body1" className={classes.genderTitle}>
                    {LanguageManager.Strings.MALE_LABEL}
                  </Typography>
                  <Radio
                    checked={this.state.femaleSelected}
                    onChange={() => this.handleChange()}
                    value="f"
                    color="primary"
                    name="radio-female"
                    aria-label="F"
                  />
                  <Typography variant="body1" className={classes.genderTitle}>
                    {LanguageManager.Strings.FEMALE_LABEL}
                  </Typography>
                </Grid>

                <Grid className={classes.genderMobileContainer}>
                  <Typography variant="h6" className={classes.genderTitle}>
                    {LanguageManager.Strings.GENDER_LABEL} :
                  </Typography>
                  <TextField
                    id="standard-select-currency"
                    select
                    className={classes.textField}
                    value={this.state.userGender}
                    onChange={this.handleGChange("gender")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                  >
                    {genders.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {this.state.smokerEnable && (
                  <div>
                    <Grid
                      xl
                      container
                      justify="flex-start"
                      alignItems="center"
                      className={classes.genderContainer}
                    >
                      <Typography variant="h6" className={classes.genderTitle}>
                        {LanguageManager.Strings.SELF_SMOKER_LABEL}
                      </Typography>

                      <Radio
                        style={{ marginLeft: "2%" }}
                        checked={this.state.smokerSelected}
                        onChange={() => this.handleSmokerChange()}
                        value="s"
                        color="primary"
                        name="radio-smoker"
                        aria-label="S"
                      />
                      <Typography
                        variant="body1"
                        className={classes.genderTitle}
                      >
                        {LanguageManager.Strings.SMOKER_OPTION}
                      </Typography>
                      <Radio
                        checked={this.state.nonSmokerSelected}
                        onChange={() => this.handleSmokerChange()}
                        value="n"
                        color="primary"
                        name="radio-noSmoker"
                        aria-label="NS"
                      />
                      <Typography
                        variant="body1"
                        className={classes.genderTitle}
                      >
                        {LanguageManager.Strings.NO_SMOKER_OPTION}
                      </Typography>
                    </Grid>

                    <Grid className={classes.genderMobileContainer}>
                      <Typography variant="h6" className={classes.genderTitle}>
                        {LanguageManager.Strings.SELF_SMOKER_LABEL} :
                      </Typography>
                      <TextField
                        id="standard-select-currency"
                        select
                        className={classes.textField}
                        value={this.state.smokerType}
                        onChange={this.handleSChange("smoker")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                        margin="normal"
                      >
                        {smokers.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid
                      xl
                      container
                      justify="flex-start"
                      alignItems="center"
                      className={classes.smokerContainer}
                    >
                      <Checkbox
                        checked={this.state.driverSmoker}
                        onChange={() =>
                          this.setState({
                            driverSmoker: !this.state.driverSmoker
                          })
                        }
                        value="driverSmoker"
                        color="primary"
                      />
                      <Typography
                        variant="body1"
                        className={classes.genderTitle}
                      >
                        {LanguageManager.Strings.DRIVER_SMOKER_QUESTN}
                      </Typography>
                    </Grid>
                  </div>
                )}
                <Grid xl container justify="flex-start" alignItems="center">
                  {this.state.isLoading ? (
                    <Grid
                      item
                      style={{
                        backgroundColor: "#23669B",
                        width: "60%",
                        marginTop: 20,
                        height: "90%",
                        borderRadius: 5
                      }}
                    >
                      <CircularProgress
                        size={30}
                        style={{
                          color: "white",
                          marginTop: "1%",
                          marginBottom: "1%"
                        }}
                      />
                    </Grid>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      disableTouchRipple={true}
                      disableFocusRipple={true}
                      className={classes.SignupButton}
                      onClick={() => this.saveProfileChanges()}
                    >
                      {LanguageManager.Strings.SAVE_BTN}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </UserLayout>
    );
  }
}

export default withStyles(styles)(UserProfileScreen);
