import { getTheme } from "../../../managers/theme/ThemeManager";
import { BUTTON } from "../../../values/Typography";


const styles = (theme) => {
    const configTheme = getTheme()

    return (
        {
            primaryButton: {
                textTransform: 'initial',
                border: 'none',
                backgroundColor: configTheme.color.primary,
                color: 'black',
                fontSize: BUTTON.FONT_SIZE,
                padding: BUTTON.PADDING,
                '&:hover': {
                    backgroundColor: configTheme.color.background,
                    color: configTheme.color.primary,
                    border: '1px solid ' + configTheme.color.primary
                },
                '&:active': {
                    backgroundColor: configTheme.color.background,
                    color: configTheme.color.primary,
                    border: '1px solid ' + configTheme.color.primary
                }
            },

            secondaryButton: {
                textTransform: 'initial',
                border: 'none',
                backgroundColor: configTheme.color.secondary,
                color: configTheme.color.background,
                fontSize: BUTTON.FONT_SIZE,
                padding: BUTTON.PADDING,
                '&:hover': {
                    backgroundColor: configTheme.color.background,
                    color: configTheme.color.secondary,
                    border: '1px solid ' + configTheme.color.secondary
                },
                '&:active': {
                    backgroundColor: configTheme.color.background,
                    color: configTheme.color.secondary,
                    border: '1px solid ' + configTheme.color.secondary
                }
            },
            primaryVariantButton: {
                textTransform: 'initial',
                border: '1px solid ' + configTheme.color.primary,
                backgroundColor: 'white',
                color: configTheme.color.primary,
                fontSize: BUTTON.FONT_SIZE,
                padding: BUTTON.PADDING,
                '&:hover': {
                    backgroundColor: configTheme.color.primary,
                    color: 'white',

                },
                '&:active': {
                    backgroundColor: configTheme.color.primary,
                    color: 'white',

                }
            }
        }
    )
}



export default styles