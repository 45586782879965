import { GOOGLE_API_KEY } from "../../constants/AppConstants";

export const TAXI_IMAGE_URL = "";

const BASE_URL = "https://stillmovin.co.za/socket/public/";
export const USERAPP_IMAGE_URL = BASE_URL;

const TAXI_BASE_URL = BASE_URL + "api/";

export const GOOGLE_AUTO_COMPLETE_API = `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${GOOGLE_API_KEY}`;

export const GOOGLE_PLACE_DETAILS_API = `https://maps.googleapis.com/maps/api/place/details/json?key=${GOOGLE_API_KEY}`;

const OPEN_STREET_BASE_URL = "https://nominatim.openstreetmap.org/";

export const OPEN_STREET_PLACE_SEARCH =
  OPEN_STREET_BASE_URL + "search.php?polygon_geojson=1&format=json&q=";

export const OPEN_STREET_POLYGON_SERIES =
  OPEN_STREET_BASE_URL + "details.php?polygon_geojson=1&format=json&place_id=";

export const GOOGLE_STATIC_MAP = `https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=600x300&key=${GOOGLE_API_KEY}&center=`;
export const NEW_SANDBOX_BASE = "http://apporio-admin-panel.getsandbox.com/";

export const APPORIO_TAXI_APIS = {
  MOCK_USER_API: "https://apporiotaxi.getsandbox.com/dashboardConfig",

  USER_GETESTIMATE: TAXI_BASE_URL + "user/checkEstimate",
  USER_CARS_NO_LOGIN: TAXI_BASE_URL + "user/carsWithoutLogin",
  USER_SAVECARD: TAXI_BASE_URL + "user/save-card",

  USER_SOCIALOGIN: TAXI_BASE_URL + "user/socialsignin",
  USER_SOCIALREGISTER: TAXI_BASE_URL + "user/socialsingup",
  USER_GETESTIMATE: TAXI_BASE_URL + "user/checkEstimate",
  USER_CARS_NO_LOGIN: TAXI_BASE_URL + "user/carsWithoutLogin",

  DRIVER_GETCONFIG: TAXI_BASE_URL + "driver/configuration",
  USER_LOGIN: TAXI_BASE_URL + "user/login",
  USER_SPECIFIC_DETAIL: TAXI_BASE_URL + "user/login",
  USER_REGISTER: TAXI_BASE_URL + "user/signup",

  USER_FAV_DRIVERS: TAXI_BASE_URL + "user/favourite/drivers",
  USER_OUTSTATION_DETAILS: TAXI_BASE_URL + "user/outstation-details",

  GET_OTP: TAXI_BASE_URL + "user/otp",
  USER_FORGOT_PASSWORD: TAXI_BASE_URL + "user/forgotpassword",
  PAYMENT_OPTIONS: TAXI_BASE_URL + "user/payment-option",
  CAR_TYPES: TAXI_BASE_URL + "user/cars",
  NEAREST_DRIVERS: TAXI_BASE_URL + "user/driver",
  CHANGE_PAYMENT_OPTION: TAXI_BASE_URL + "user/payment",

  CHANGE_PAYMENT_OPTION: TAXI_BASE_URL + "user/payment",

  DRIVER_ADDNEWCAR: TAXI_BASE_URL + "driver/addvehicle",
  DRIVER_ADD_EXISTING_CAR: TAXI_BASE_URL + "driver/vehicleRequest",

  DRIVER_DETAILS: TAXI_BASE_URL + "driver/details",

  DRIVER_GET_DOCSLIST: TAXI_BASE_URL + "driver/documentlist",

  DRIVER_CARDOCUPLOAD: TAXI_BASE_URL + "driver/addvehicledocument",
  DRIVER_DOCUPLOAD: TAXI_BASE_URL + "driver/adddocument",
  DRIVER_GETCARTYPES: TAXI_BASE_URL + "driver/vehicleconfiguration",
  DRIVER_LOGIN: TAXI_BASE_URL + "driver/login",
  DRIVER_SIGNUP: TAXI_BASE_URL + "driver/firtstep",

  ACTIVE_TRIPS: TAXI_BASE_URL + "driver/booking/history/active",
  PAST_TRIPS: TAXI_BASE_URL + "driver/booking/history/past",
  SCH_TRIPS: TAXI_BASE_URL + "driver/booking/history/schedule",
  HISTORY_DETAIL: TAXI_BASE_URL + "driver/booking/history/detail",
  CHECKOUT_API: TAXI_BASE_URL + "user/checkout",
  ADD_FAVOURITE_LOCATION: TAXI_BASE_URL + "user/favourite/location",
  USER_FAVOURITE_LOCATION_LIST: TAXI_BASE_URL + "user/viewfavourite",
  BOOKING_CONFIRM: TAXI_BASE_URL + "user/confirm",
  APPLY_COUPON: TAXI_BASE_URL + "user/checkout/apply-promo",
  SELECT_FAV_DRIVER: TAXI_BASE_URL + "user/favourite/drivers",
  USER_PAST_TRIP: TAXI_BASE_URL + "user/booking/history",
  USER_ACTIVE_TRIP_API: TAXI_BASE_URL + "user/booking/history/active",
  USER_AUTO_CANCEL_API: TAXI_BASE_URL + "user/booking/autocancel",
  USER_SPECIFIC_DETAILS: TAXI_BASE_URL + "user/booking/history/detail",
  USER_BOOKING_EMAIL: TAXI_BASE_URL + "user/booking/invoice/",
  USER_CUSTOMER_SUPPORT: TAXI_BASE_URL + "user/customer_support",
  USER_TRIP_CANCEL_REASONS: TAXI_BASE_URL + "user/cancelReasons",
  USER_TRIP_CANCEL: TAXI_BASE_URL + "user/booking/cancel",
  USER_TRANSACTION_LOG: TAXI_BASE_URL + "user/wallet/transaction", //api/user/wallet/transaction
  USER_DETAILS: TAXI_BASE_URL + "user/UserDetail",
  USER_PROFILE_EDIT: TAXI_BASE_URL + "user/edit-profile",
  USER_HOME_LIST: TAXI_BASE_URL + "user/website/homeScreen",
  MANAGE_VEHICLES: TAXI_BASE_URL + "driver/allVehicles",
  FETCH_MODELS: TAXI_BASE_URL + "driver/vehiclemodel",
  MANAGE_VEHICLES: TAXI_BASE_URL + "driver/allVehicles",
  FETCH_MODELS: TAXI_BASE_URL + "driver/vehiclemodel",
  DRIVER_EARNINGS: TAXI_BASE_URL + "driver/earnings",
  DRIVER_ADDRESS: TAXI_BASE_URL + "driver/homeaddress",
  DRIVER_DETAILS: TAXI_BASE_URL + "driver/details",
  ACC_TYPE: TAXI_BASE_URL + "driver/account-types",
  EDIT_BANK: TAXI_BASE_URL + "driver/bankdetails",
  EDIT_PROFILE: TAXI_BASE_URL + "driver/edit-profile",
  ADD_ADDRESS: TAXI_BASE_URL + "driver/add/homeaddress",
  DELETE_DRIVER_ADDRESS: TAXI_BASE_URL + "driver/delete/homelocation",
  SELECT_DRIVER_HOME_ADDRESS: TAXI_BASE_URL + "driver/select/homelocation",
  DRIVER_ADDRESS_ACTIVE_DEACTIVE:
    TAXI_BASE_URL + "driver/active/deactive/homelocation",
  DRIVER_PERSONAL_DOCUMENT: TAXI_BASE_URL + "driver/personal/documentlist",
  ADD_ADDRESS: TAXI_BASE_URL + "driver/add/homeaddress",
  ADD_DRIVER_DOCUMENT: TAXI_BASE_URL + "driver/adddocument",
  DRIVER_WALLET: TAXI_BASE_URL + "driver/wallet/transaction",
  DRIVER_CARDS: TAXI_BASE_URL + "driver/cards",
  CHANGE_PASSWORD: TAXI_BASE_URL + "driver/changepassword",
  USER_CONFIG: TAXI_BASE_URL + "user/configuration",
  SAVE_DRIVER_CARDS: TAXI_BASE_URL + "driver/savecards",
  CHANGE_PASSWORD: TAXI_BASE_URL + "driver/changepassword",
  DRIVER_OTP_GEN: TAXI_BASE_URL + "driver/otp",
  DRIVER_FORGOT_PASSWORD: TAXI_BASE_URL + "driver/forgotpassword",
  USER_CARDS: TAXI_BASE_URL + "user/cards",
  USER_ADDTOWALLET: TAXI_BASE_URL + "user/wallet/addMoney",
  DRIVER_ADDTOWALLET: TAXI_BASE_URL + "driver/wallet/addMoney",
  CHANGE_VEHICLE: TAXI_BASE_URL + "driver/changeVehicle",
  DRIVER_CMSDATA: TAXI_BASE_URL + "driver/cms/pages",
  USER_CMSDATA: TAXI_BASE_URL + "user/cms/pages",

  DRIVER_HOME_LISTING: TAXI_BASE_URL + "driver/website/homeScreen",
  BOOKING_STATUS: TAXI_BASE_URL + "bookingStatus",
  DASHBOARD_CAR_DETAILS: TAXI_BASE_URL + "user/website/service",
  BOOKING_DETAILS: TAXI_BASE_URL + "user/booking/details",
};
