import React from "react";
import DialogForm from "./DialogForm";
import { withStyles, Paper, InputBase, ButtonBase } from "@material-ui/core";
import { Button, Grid, Typography, TextField } from "@material-ui/core";

import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import CustomLoader from "../../../custom/CustomLoader";
import { CUSTOM_FONT } from "../../../../values/Typography";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import * as LanguageManager from "../../../../managers/language/LanguageManager";

const today = new Date();

class DocumentUploader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      image: props.imageUrl ? props.imageUrl : null,
      date: props.date ? props.date : "",
      dateRequired:
        props.dateRequired == undefined || props.dateRequired == true
          ? true
          : false,
      disabled: Boolean(props.disabled) ? true : false,
      snack: {
        open: false,
        variant: "error",
        message: LanguageManager.Strings.REQ_FIELDS_ALERT
      },
      active: 1,
      expiry: 2,
      isLoading: false,
      showSnackbar: false,
      snackbarVariant: "error",
      snakBarMessage: ""
    };
  }

  componentDidMount() {
    console.log("EEEE" + JSON.stringify(this.props));
  }

  // ---- open explorer ----
  openExplorer = () => {
    this.imageRef.current.click();
  };

  // --- handle upload ----
  handleUpload = event => {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({
        driverImage: e.target.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  // ---- handle date ----
  handleDate = event => {
    this.setState({
      date: event.target.value
    });
  };

  handleChange(event) {
    console.log("QQQQ" + event.target.value);
    let date = String(event.target.value);
    let dateArr = date.split("-");

    // if(dateArr[0]>=today.getFullYear){
    //     if(dateArr[1]>=today.getMonth){
    //         if(dateArr[2]>=today.getDay){
    this.setState({ expiryDate: event.target.value });
    //         }else{
    //             this.setState({
    //                 showSnackbar: true,
    //                 snakBarMessage: "Please select valid date of Expiry.",
    //                 isLoading: false,
    //                 snackbarVariant:"warning"
    //               });
    //         }}else{
    //     this.setState({
    //         showSnackbar: true,
    //         snakBarMessage: "Please select valid date of Expiry.",
    //         isLoading: false,
    //         snackbarVariant:"warning"
    //       });
    // }}else{
    //     this.setState({
    //         showSnackbar: true,
    //         snakBarMessage: "Please select valid date of Expiry.",
    //         isLoading: false,
    //         snackbarVariant:"warning"
    //       });
    // }
  }

  validateDetails(driverID, docID, vehicleID, loginKey) {
    console.log("Q@Q@Q@Q@", driverID, docID, vehicleID, loginKey);
    console.log("AAAAA" + String(this.state.expiryDate));
    let today = new Date();
    let date = String(this.state.expiryDate);
    let dateArr = date.split("-");
    console.log(
      "RRRRRR" + dateArr[0] + "_" + dateArr[1] + "_" + dateArr[2] + "_"
    );
    if (this.state.expiryDate != "" && this.props.expire_date != 2) {
      if (dateArr[0] > today.getFullYear()) {
        if (this.props.carDocMode) {
          this.submitVehicleDoc(driverID, docID, vehicleID, loginKey);
        } else {
          this.submitPersonalDoc(driverID, docID, loginKey);
        }
      } else if (dateArr[0] == today.getFullYear()) {
        if (dateArr[1] >= 1 + today.getMonth()) {
          if (dateArr[2] > today.getDate()) {
            if (this.props.carDocMode) {
              this.submitVehicleDoc(driverID, docID, vehicleID, loginKey);
            } else {
              this.submitPersonalDoc(driverID, docID, loginKey);
            }
          } else {
            this.setState(
              {
                showSnackbar: true,
                snakBarMessage: LanguageManager.Strings.VALID_DAY_ALERT,
                isLoading: false,
                snackbarVariant: "warning"
              },
              () => console.log(dateArr[2] + "||" + today.getDate())
            );
          }
        } else {
          this.setState(
            {
              showSnackbar: true,
              snakBarMessage: LanguageManager.Strings.VALID_MONTH_ALERT,
              isLoading: false,
              snackbarVariant: "warning"
            },
            () => console.log(dateArr[1] + "||" + today.getMonth())
          );
        }
      } else {
        this.setState(
          {
            showSnackbar: true,
            snakBarMessage: LanguageManager.Strings.VALID_YEAR_ALERT,
            isLoading: false,
            snackbarVariant: "warning"
          },
          () => console.log(dateArr[0] + "||" + today.getFullYear())
        );
      }
    } else if (this.props.carDocMode) {
      this.submitVehicleDoc(driverID, docID, vehicleID, loginKey);
    } else {
      this.submitPersonalDoc(driverID, docID, loginKey);
    }
  }
  //     if (this.state.expiryDate) {
  //       if (dateArr[0] >= today.getFullYear()) {
  //         if (dateArr[1] <= 1 + today.getMonth()) {
  //           if (dateArr[2] < today.getDate()) {
  //             if (this.props.carDocMode) {
  //               this.submitVehicleDoc(driverID, docID, vehicleID, loginKey);
  //             } else {
  //               this.submitPersonalDoc(driverID, docID, loginKey);
  //             }
  //           } else {
  //             this.setState(
  //               {
  //                 showSnackbar: true,
  //                 snakBarMessage: LanguageManager.Strings.VALID_DAY_ALERT,
  //                 isLoading: false,
  //                 snackbarVariant: "warning"
  //               },
  //               () => console.log(dateArr[2] + "||" + today.getDate())
  //             );
  //           }
  //         } else {
  //           this.setState(
  //             {
  //               showSnackbar: true,
  //               snakBarMessage: LanguageManager.Strings.VALID_MONTH_ALERT,
  //               isLoading: false,
  //               snackbarVariant: "warning"
  //             },
  //             () => console.log(dateArr[1] + "||" + today.getMonth())
  //           );
  //         }
  //       } else {
  //         this.setState(
  //           {
  //             showSnackbar: true,
  //             snakBarMessage: LanguageManager.Strings.VALID_YEAR_ALERT,
  //             isLoading: false,
  //             snackbarVariant: "warning"
  //           },
  //           () => console.log(dateArr[0] + "||" + today.getFullYear())
  //         );
  //       }
  //     } else {
  //       this.setState(
  //         {
  //           showSnackbar: true,
  //           snakBarMessage: LanguageManager.Strings.EXPIRY_DATE_ALERT,
  //           isLoading: false,
  //           snackbarVariant: "warning"
  //         },
  //         () => console.log(dateArr[0] + "||" + today.getFullYear())
  //       );
  //     }
  //   }

  submitPersonalDoc(driverID, docID, loginKey) {
    if (this.state.driverImage) {
      // if (this.state.expiryDate) {
      this.setState({ isLoading: true });

      ApiManager.callPostApi(
        "DocumentUpload",
        "driver document add api",
        ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_DOCUPLOAD,
        {
          api_type: 1,
          type: 1,
          driver: driverID,
          document: docID,
          expire_date: this.state.expiryDate,
          document_image: this.state.driverImage
        }
      ).then(res => {
        if (res.result == 1) {
          this.setState({ isLoading: false });
          let resp = {};
          resp["docStatus"] = res.data.document_verification_status;
          resp["id"] = this.props.keyValue;
          resp["status"] = res.result;
          resp["driverID"] = driverID;
          if (loginKey == 1) {
            resp["afterLogin"] = 1;
          }

          this.props.callback(resp);
        } else {
          alert(res.message);
          this.setState({ isLoading: false });
        }

        // this.props.callBack(idVal+1)
      });
      // } else {
      //   this.setState({
      //     showSnackbar: true,
      //     snakBarMessage: LanguageManager.Strings.EXPIRY_DATE_ALERT,
      //     isLoading: false,
      //     snackbarVariant: "warning"
      //   });
      // }
    } else {
      this.setState({
        showSnackbar: true,
        snakBarMessage: LanguageManager.Strings.DOC_IMAGE_ALERT,
        isLoading: false,
        snackbarVariant: "warning"
      });
    }
  }

  submitVehicleDoc(driverID, docID, vehicleID, loginKey) {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "DocumentUpload",
      "car_document_add_api",
      ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_CARDOCUPLOAD,
      {
        api_type: 1,
        type: 1,
        driver: driverID,
        document: docID,
        expire_date: this.state.expiryDate,
        driver_vehicle_id: vehicleID,
        document_image: this.state.driverImage
      }
    ).then(res => {
      if (res.result == 1) {
        this.setState({ isLoading: false });
        let resp = {};
        resp["docStatus"] = res.data.document_verification_status;
        resp["id"] = this.props.keyValue;
        resp["status"] = res.result;
        if (loginKey == 1) {
          resp["afterLogin"] = 1;
        }

        this.props.callback(resp);
      } else {
        alert(res.message);
        this.setState({ isLoading: false });
      }

      // this.props.callBack(idVal+1)
    });
  }

  // ---- upload doc ----
  uploadDoc = () => {
    if (
      this.state.driverImage == null ||
      (Boolean(this.state.dateRequired) && this.state.date == "")
    ) {
      this.setState({
        snack: {
          ...this.state.snack,
          open: true
        }
      });

      return;
    }

    this.props.handleDocs(this.state.driverImage, this.state.date);
  };

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  render() {
    const {
      classes,
      keyValue,
      driverID,
      docID,
      carDocMode,
      vehicleID,
      postLogin
    } = this.props;
    return (
      <div>
        {console.log("this.props.expire_date", this.props.expire_date)}
        {console.log("RQRQ" + carDocMode)}
        <CustomLoader showLoader={this.state.isLoading} />

        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />

        <DialogForm
          centerColor={this.props.color ? this.props.color : "#666666"}
          visible={this.props.visible}
          onOutsideTouch={this.props.onClose}
        >
          <div className={classes.uploader}>
            <Typography variant="h4" className={classes.heading} align="center">
              {LanguageManager.Strings.UPLOAD_DOC_LABEL}
            </Typography>
            <Typography variant="subtitle" className={classes.detail}>
              {LanguageManager.Strings.UPLOAD_DOC_MESSAGE}
            </Typography>

            <Grid container xl className={classes.containerStyle}>
              {/* <div className={classes.box}>

                                <Paper className={classes.imageBox}>
                                    <Button variant="outlined"
                                        onClick={() => this.openExplorer()}
                                        disabled={this.state.disabled}>
                                        {
                                            (Boolean(this.state.driverImage)) ?
                                                <img src={this.state.driverImage} className={classes.defaultImage}/>
                                                :
                                                <img src="https://image.flaticon.com/icons/svg/1242/1242455.svg" className={classes.defaultImage}/>
                                        }
                                    </Button>
                                    <input type="file" ref={this.imageRef} style={{ width: '0', height: '0', overflow: 'hidden' }}
                                        onChange={(event) => this.handleUpload(event)}
                                    ></input>
                                </Paper>
                                <Typography variant="subtitle" className={classes.detail} align="center">
                                    Select document <br/>image of good quality <br/> & expiry date of <br/>the document.
                                </Typography>

                            </div> */}

              <Grid item className={classes.avatarGrid}>
                <ButtonBase
                  accept="image/*"
                  htmlFor="icon-button-file"
                  id="icon-button-file"
                  focusRipple
                  onClick={() => this.openExplorer()}
                >
                  <Grid item className={classes.avatarContainer}>
                    {Boolean(this.state.driverImage) ? (
                      <img
                        src={this.state.driverImage}
                        className={classes.avatarStyle}
                      />
                    ) : (
                      <img
                        src="https://image.flaticon.com/icons/svg/1242/1242455.svg"
                        className={classes.avatarStyle}
                      />
                    )}
                  </Grid>
                </ButtonBase>
                <input
                  type="file"
                  ref={this.imageRef}
                  display="none"
                  style={{ width: "0", height: "0", overflow: "hidden" }}
                  onChange={event => this.handleUpload(event)}
                />

                <Typography
                  variant="body2"
                  className={classes.avatarText}
                  align="center"
                >
                  {LanguageManager.Strings.UPLOAD_DOCIMAGE_LABEL}
                </Typography>
              </Grid>

              <div className={classes.expiryContainer}>
                {this.props.expire_date != 2 && (
                  <div>
                    <Typography
                      variant="h6"
                      className={classes.validLabel}
                      align="left"
                    >
                      {LanguageManager.Strings.VALID_THRU_LABEL}
                    </Typography>
                    <form className={classes.container} noValidate>
                      <TextField
                        id="date"
                        label={LanguageManager.Strings.EXPIRY_HINT}
                        type="date"
                        onChange={evt => this.handleChange(evt)}
                        defaultValue={
                          today.getDate() +
                          "/" +
                          today.getMonth() +
                          1 +
                          "/" +
                          today.getFullYear()
                        }
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </form>
                  </div>
                )}
                {/* <Button fullWidth variant="contained" color="primary" disableTouchRipple={true} disableFocusRipple={true} className={classes.SignupButton} onClick={()=>{(carDocMode)?this.validateDetails(driverID,docID,vehicleID,postLogin):this.validateDetails(driverID,docID,postLogin)}}> */}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disableTouchRipple={true}
                  disableFocusRipple={true}
                  className={classes.SignupButton}
                  onClick={() =>
                    this.validateDetails(driverID, docID, vehicleID, postLogin)
                  }
                >
                  {LanguageManager.Strings.SUBMIT_BTN_TITLE}
                </Button>
              </div>
            </Grid>

            {/* <div className={classes.footer}>
                            <Button className={classes.addButton}
                                onClick={() => this.uploadDoc()}
                                disabled={this.state.disabled}
                            >Upload Doc</Button>
                        </div> */}
          </div>
        </DialogForm>
        {/* {
                    // snackbar
                    (this.state.snack.open) &&
                    <SaifSnackbar
                        open={this.state.snack.open}
                        variant={this.state.snack.variant}
                        message={this.state.snack.message}
                        onClose={() => {
                            this.setState({
                                snack: {
                                    ...this.state.snack,
                                    open: false
                                }
                            })
                        }}
                    />
                } */}
      </div>
    );
  }
}

const styles = theme => ({
  uploader: {
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "400px"
    },
    [theme.breakpoints.down("md")]: {
      overflowY: "auto"
    },

    width: "60vw",
    height: "90vh"
    // [theme.breakpoints.down('md')]: {
    //     width: 'calc(100vw - 140px)'
    // }
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  expiryContainer: {
    paddingLeft: theme.spacing.unit * 5,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    }
    // marginTop:theme.spacing.unit*10
  },
  defaultImage: {
    width: 100,
    height: 100
  },
  validLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    },
    fontWeight: "bold",
    fontFamily: "Montserrat"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    }
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 20
    },
    marginTop: theme.spacing.unit * 5,
    fontFamily: "Montserrat", //FONT_FAMILY,
    fontWeight: "bold"
  },
  detail: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 8,
      paddingRight: theme.spacing.unit * 2,
      paddingLeft: theme.spacing.unit * 2
    },
    fontFamily: "Montserrat",
    // paddingRight: '20%',
    // paddingLeft: '20%',//FONT_FAMILY,
    fontWeight: "bold"
  },
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  addButton: {
    textTransform: "capitalize",
    backgroundColor: "green", //colorMutedGreen,
    color: "black", // pureWhite,
    padding: "3px 10px",
    fontWeight: 600
  },
  box: {
    justifyContent: "center",
    flexDirection: "column"
  },
  imageBox: {
    backgroundSize: "contain",
    backgroundColor: "#EBEBEB",
    justifyContent: "center",
    // alignItems: 'center',
    width: "40%",
    height: "50%",
    //marginBottom: '15px',
    //overflow: 'hidden',
    "& button": {
      width: "40%",
      height: "50%",
      boxShadow: "none",
      border: "none"
    }
  },
  inputBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    boxShadow: "none",
    marginBottom: "15px"
  },
  label: {
    color: "black", // pureWhite,
    alignSelf: "start",
    fontWeight: 600,
    fontFamily: CUSTOM_FONT
  },
  SignupButton: {
    fontFamily: CUSTOM_FONT,
    fontSize: 18,
    textTransform: "none",
    marginTop: "150px",
    // height: '5vh',
    color: "black",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px"
    }
  },
  containerStyle: {
    marginTop: theme.spacing.unit * 10,
    marginBottom: theme.spacing.unit * 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 0
    }
  },
  avatarText: {
    fontFamily: CUSTOM_FONT,
    fontSize: 16,
    // marginLeft:theme.spacing.unit*2,
    // marginRight:theme.spacing.unit*3,
    marginTop: "5px",
    justifyContent: "flex-start",
    // marginRight: 20,
    // marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 8
    }
  },
  avatarContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "silver",
    border: "1px solid gray",
    borderRadius: 5,
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down("sm")]: {
      //width:'100%',
      //height:
    }
    // paddingBottom: theme.spacing.unit * 5,
    // paddingRight: theme.spacing.unit * 5,
    // paddingLeft: theme.spacing.unit * 5
    //height:'30vh'
  },
  avatarGrid: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  avatarStyle: {
    width: "50%",
    height: "50%"
  },
  [theme.breakpoints.down("sm")]: {
    width: "50%"
  }
});

export default withStyles(styles)(DocumentUploader);
