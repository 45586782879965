import { getTheme } from "../../../../managers/theme/ThemeManager";
import {
  PADDING,
  MARGIN,
  CUSTOM_FONT,
  CUSTOM_COLOUR,
  CUSTOM_DRIVER_COLOUR
} from "../../../../values/Typography";

const styles = theme => {
  const configTheme = getTheme();

  return {
    root: {
      flexGrow: 1,
      // backgroundColor:'red',
      "&$tabSelected": {
        color: "red",
        fontWeight: "bold" //theme.typography.fontWeightMedium,
      }
    },

    tabRoot: {
      textTransform: "initial",
      fontFamily: CUSTOM_FONT,
      fontSize: 22,
      "&$tabSelected": {
        fontWeight: "bold" //theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "black"
      }
    },
    tabSelected: {},
    tabStyle: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 22
    },
    tabsIndicator: {
      backgroundColor: "black"
    },
    listItem: {
      fontFamily: CUSTOM_FONT,
      fontSize: 22
    },
    SignupButton: {
      backgroundColor: CUSTOM_DRIVER_COLOUR,
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      width: "60%",
      marginBottom: 20,
      marginTop: 20,
      height: "5vh",
      color: "black",
      fontWeight: "bold"
    },
    detailFields: {
      height: 40
    },
    materialFieldStyle: {
      fontSize: 12,
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold"
    },
    listItem: {
      fontSize: 12,
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold"
    },
    sectionHead: {
      backgroundColor: "silver",
      paddingLeft: 10,
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold"
    }
  };
};

export default styles;
