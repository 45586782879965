import { CUSTOM_FONT, CUSTOM_COLOUR } from "../../../values/Typography";

const Styles = theme => ({
  mayaLinks: {
    color: "silver",
    fontSize: 14,
    "&:hover": {
      color: "#FFF"
    }
  },
  card: {
    maxWidth: 250,
    height: 100,
    backgroundColor: "red"
  },
  media: {
    height: 60,
    width: 150
  },
  parentContainer: {
    flexGrow: 1,
    marginTop: 550,
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: 300
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 200
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 150
    }
  },
  featureGrid: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  featureCard: {
    display: "flex",
    flexDirection: "column",
    padding: "2%",
    [theme.breakpoints.up("sm")]: {
      borderRight: null
    }
  },
  carImage: {
    width: "30%",
    display: "flex",
    backgroundSize: "contain",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  demo: {
    //width: '100%',
    justify: "space-between",
    backgroundColor: "#F8F9FA",
    direction: "row",
    flex: 1,
    paddingLeft: theme.spacing.unit * 3,
    //paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 1
  },
  unitLogo: {
    backgroundSize: "contain",
    height: "15vh",
    width: "15vw",
    margin: "5px 10px"
    // marginTop: theme.spacing.unit * 1,
    // marginBottom: theme.spacing.unit * 1
  },

  headerButton: {
    color: "black",
    fontSize: 12,
    fontFamily: CUSTOM_FONT
  },
  resumeButton: {
    color: "black",
    fontSize: 12,
    backgroundColor: "gold",
    fontFamily: CUSTOM_FONT
  },
  headerButtonView: {
    fontFamily: CUSTOM_FONT,
    fontSize: 12,
    backgroundColor: CUSTOM_COLOUR,
    borderRadius: 5
  },
  headerLink: {
    fontFamily: CUSTOM_FONT,
    color: "#616A6B",
    fontSize: 14
    //marginLeft: "0.3rem",
    //marginRight: "0.3rem"
  },
  logoContainer: {
    justify: "center",
    alignItems: "center",
    flex: 1,
    marginBottom: 10
  },
  backgroundImage: {
    height: 400,
    direction: "row",
    justify: "flex-end",
    objectFit: "cover"
  },

  bgImageMaya: {
    height: 900,
    justify: "center"
  },

  homeFormContainer: {
    height: "300px",
    backgroundColor: "rgba(255,255,255,0.4)",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    marginRight: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: null,
      marginLeft: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      width: "60%"
    },
    [theme.breakpoints.up("md")]: {
      width: "40%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%"
    }
  },
  listContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: 100
    }
  },
  bootstrapRoot: {
    boxShadow: "none",
    //textTransform: 'none',
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },

  // button: {
  //   direction: "row",
  //   justify: "flex-start",
  //   alignItems: "center",
  //   height: "50px",
  //   marginTop: "2rem"
  //   //flex: 0.3
  // },
  button: {
    borderRadius: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: "white",
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    textTransform: "none",
    color: "black",
    backgroundColor: "#006699",
    //disableFocusRipple: true,
    flex: 1
  },
  mayaButton: {
    margin: theme.spacing.unit,
    textTransform: "none",
    backgroundColor: "#FFF",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#FFF",
      color: "#3B86B0"
    }
  },
  formViewContainer: {
    direction: "row",

    justify: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center"
    }
  },
  pickDropContainer: {
    backgroundColor: "white",
    borderRadius: 5,
    direction: "row",
    justify: "flex-start"
  },
  sourceDestnIcon: {
    height: 100,
    width: 30
  },
  pickIcon: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: "green"
  },
  dropIcon: {
    height: 20,
    width: 20,
    //borderRadius:10,
    backgroundColor: "red",
    justify: "center",
    alignItems: "center",
    direction: "column",
    padding: 2
  },
  iconContainer: {
    direction: "column",
    justify: "space-between",
    flex: 1,
    padding: theme.spacing.unit * 1
  },
  dropInnerCircle: {
    borderColor: "white",
    borderWidth: 1,
    width: "90%",
    height: "90%"
    // backgroundColor:' blue',
    ///borderRadius:10,
  },
  gotoIcon: {
    height: 30,
    width: 30,
    color: "red",
    marginTop: 15
  },
  leaveIcon: {
    height: 15,
    width: 15,
    borderRadius: 10 / 2,
    color: "green"
  },
  fieldContainer: {
    flex: 10,
    direction: "column",
    justify: "flex-start"
  },
  margin: {
    margin: theme.spacing.unit
  },
  feildInput: {
    width: "100%",
    fontSize: "0.8rem",
    // marginTop: 5,
    marginLeft: 10
  },
  featureTitle: {
    fontWeight: "bold",
    fontSize: "3rem",
    color: "white",
    fontFamily: CUSTOM_FONT,
    marginLeft: "15%"
  },
  root: {
    display: "flex",
    flex: 1,
    // flexWrap: 'wrap',
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  estimateContainer: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  carImageContainer: {
    flex: 1,
    //backgroundColor:'red',
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  phoneImage: {
    width: "50%",
    height: "auto",
    backgroundSize: "cover"
  },
  phoneCompatibleFeatureView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    display: "none",
    justifyContent: "flex-start",
    flex: 1,
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      display: "flex"
    }
  },
  featuresView: {
    display: "none",
    justifyContent: "center",
    flex: 3,
    padding: "1rem",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  characterGrid: {
    alignSelf: "center",
    marginLeft: theme.spacing.unit * 5,
    marginRight: theme.spacing.unit * 5,
    marginTop: "5px",
    padding: "1%",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit
    }
  },
  featureBoardContainer: {
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "column",
    direction: "column"
  },
  appleImg: {
    // width:200,
    height: 90
  },
  androidImg: {
    // width:200,
    height: 90
  },

  image: {
    marginTop: "5%",
    position: "relative",
    height: 50,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 50
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 1,
        backgroundColor: theme.palette.common.white
      },
      // "& $imageMarked": {
      //   opacity: 0
      // },
      "& $imageTitle": {
        borderBottom: "4px solid currentColor"
      }
    }
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f9027e" //theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
  },
  imageBackdrop: {
    borderRadius: 100,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.8,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${0}px ${0}px ${0}px`
  }
});
export default Styles;
