import { getTheme } from "../../../../managers/theme/ThemeManager";
import {
  PADDING,
  MARGIN,
  CUSTOM_FONT,
  CUSTOM_COLOUR,
  CUSTOM_DRIVER_COLOUR
} from "../../../../values/Typography";

const styles = theme => {
  const configTheme = getTheme();

  return {
    root: {
      flexGrow: 1
    },
    formPaper: {
      flex: 1,
      margin: theme.spacing.unit,
      height: "80vh",
      width: "100vw",
      overFlowY: "scroll"
      /// paddingBottom:theme.spacing.unit*5,
      //paddingTop:theme.spacing.unit*2
    },
    backgroundImage: {
      height: "100vh",
      width: "100%"
    },
    letterContainer: {
      //  width:'40%',
      overFlowY: "scroll",
      paddingRight: 10,
      paddingLeft: 10
    },
    parentView: {
      height: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
      }
      // marginTop:'20vh',
      // height:'50vh',
      // paddingRight:theme.spacing.unit*10,
    },
    textView: {
      paddingTop: theme.spacing.unit * 2
    },
    imageView: {
      display: "flex",
      width: "30%",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
      //justify:'center',
      // padding:theme.spacing.unit*4
    },
    footerTitle: {
      color: "#45BFC6",
      fontFamily: CUSTOM_FONT
    },
    footerText: {
      color: "white",
      fontFamily: CUSTOM_FONT
    },
    media: {
      height: "100%",
      width: "60%",
      color: "white"
    },
    footerDiv: {},
    footerTitlePart: {
      fontWeight: "bold",
      color: "white",
      fontFamily: CUSTOM_FONT
    },
    heroBackground: {
      height: "100vh",
      width: "100%"
    },
    logoContainer: {
      flex: 1,
      paddingRight: theme.spacing.unit,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    loginFormSheet: {
      paddingRight: theme.spacing.unit * 10,
      paddingLeft: theme.spacing.unit * 10,
      paddingTop: theme.spacing.unit * 5,
      paddingBottom: theme.spacing.unit * 5,
      flex: 1
    },
    loginTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      marginTop: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    genderTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    smokerTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 9
      }
    },
    userNameStyle: {
      width: "50%",
      height: 100,
      fontFamily: CUSTOM_FONT
    },
    SignupButton: {
      backgroundColor: CUSTOM_DRIVER_COLOUR,
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      width: "100%",
      marginTop: 20,
      color: "black",
      fontWeight: "bold"
    },
    FPbutton: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      fontWeight: "bold"
    },
    SUbutton: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      fontWeight: "bold"
    },
    helperText: {
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      fontWeight: "bold"
    },
    // textFeild: {
    //   fontFamily: CUSTOM_FONT,
    //   fontSize: 22,
    //   border: "0px solid",
    //   outline: "none !important"
    // },

    formLink: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 9
    },
    linkContainer: {
      paddingRight: theme.spacing.unit * 3,
      paddingLeft: theme.spacing.unit * 3,
      marginTop: 20
    },
    formContainer: {
      width: "50vw",
      height: "90vh",
      overFlowY: "scroll",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "95vw",
        height: "95vh"
      }
      // padding:theme.spacing.unit,
      //paddingRight:theme.spacing.unit*5,
      //paddingLeft:theme.spacing.unit*5
    },
    // bottomLineElement: {
    //   height: "50vh",
    //   marginTop: "4vh"
    // },
    passwordGrid: {
      marginTop: 20
    },
    avatarText: {
      fontFamily: CUSTOM_FONT,
      fontSize: 16,
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 3,
      marginTop: "5px",
      justifyContent: "flex-start",
      // marginRight: 20,
      // marginLeft: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      }
    },
    avatarContainer: {
      backgroundColor: "silver",
      border: "1px solid gray",
      borderRadius: 5
      // paddingBottom: theme.spacing.unit * 5,
      // paddingRight: theme.spacing.unit * 5,
      // paddingLeft: theme.spacing.unit * 5
      //height:'30vh'
    },
    avatarGrid: {
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
      // paddingRight: theme.spacing.unit * 3,
      // paddingLeft: theme.spacing.unit * 3
    },
    genderContainer: {
      marginTop: 10
    },
    paper: {
      height: 140,
      width: 100
    },
    control: {
      padding: theme.spacing.unit * 2
    },
    bgImage: {
      height: "100%"
    },
    rootMenu: {
      display: "flex",
      flexWrap: "wrap"
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2
    },
    areaItem: {
      fontFamily: CUSTOM_FONT,
      fontSize: 14
    },
    avatarStyle: {
      width: "10vw",
      height: "20vh",
      [theme.breakpoints.down("sm")]: {
        width: "35vw",
        height: "20vh"
      }
    },
    DriverProfileUpload: {
      width: "10vw",
      height: "25vh",
      [theme.breakpoints.down("xs")]: {
        width: "25vw"
      }
    },
    textFeild: {
      fontFamily: CUSTOM_FONT,
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      },
      border: "0px solid",
      outline: "none !important"
    },
    OtpSignupDialog: {
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    bottomLineElement: {
      width: "70%",
      height: "100px",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    AllowUser: {
      marginLeft: 8,
      [theme.breakpoints.down("xs")]: {
        margin: 0
      }
    },
    AllowUserTitle: {
      marginTop: 13,
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        marginTop: 17
      }
    }
  };
};

export default styles;
