import { getTheme } from "../../../../managers/theme/ThemeManager";
import {
  MARGIN,
  CUSTOM_FONT,
  CUSTOM_COLOUR
} from "../../../../values/Typography";

const styles = theme => {
  const configTheme = getTheme();

  return {
    unitLogo: {
      backgroundSize: "contain",
      height: "15vh",
      width: "15vw",
      margin: "5px 10px"
    },

    companyLogo: {
      // width: "20%",
      display: "flex",
      backgroundSize: "contain",
      backgroundPosition: "left",
      backgroundRepeat: "no-repeat"
    },
    navbar: {
      backgroundColor: "white !important",
      boxShadow: "0 1px 3px gray"
    },
    link: {
      marginLeft: MARGIN
    },
    buttonLink: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      color: "#23669B",
      // fontWeight:'SemiBold',
      width: 100,
      backgroundColor: "red",
      fontSize: "1rem"
    },

    buttonBlock: {
      //marginLeft:10,
      width: 100,
      backgroundColor: CUSTOM_COLOUR,
      fontFamily: CUSTOM_FONT,
      textTransform: "none",
      color: "black",
      //  fontWeight:'bold',
      fontSize: "1rem"
    },
    headerLinks: {
      justifyContent: "flex-end",
      marginBottom: 10,
      marginTop: 10,
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex"
      }
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    },
    menuElement: {
      fontFamily: CUSTOM_FONT,
      fontSize: "1rem"
    },
    logInElement: {
      backgroundColor: "#3F51B5",
      fontFamily: CUSTOM_FONT,
      fontSize: "1rem",
      color: "black"
    },
    media: {
      height: 60,
      width: 150
    },
    mayaButton: {
      margin: theme.spacing.unit,
      textTransform: "none",
      backgroundColor: "#FFF",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#FFF",
        color: "#3B86B0"
      }
    }
  };
};

export default styles;
