import React, { Component } from "react";
import {
  Typography,
  InputBase,
  Button,
  CardActionArea,
  withStyles
} from "@material-ui/core";

import * as ApiManager from "../../../../managers/api/ApiManager";

import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import CustomLoader from "../../../custom/CustomLoader";
import { APPORIO_TAXI_APIS } from "../../../../managers/api/ApiEndPoints";
import MenuItem from "@material-ui/core/MenuItem";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import Select from "@material-ui/core/Select";
import { MAX_CHARACTERS, PHONE_REGEX } from "../../../../values/Typography";

const style = theme => {};

class DriverOtpVerification extends Component {
  state = {
    phone_number: "",
    driverEmail: "",
    verificatonotp: "",
    enterotp: "",
    isLoading: false,
    isCheckOtp: false,
    country_code: this.props.countrySet,
    phonecode: "",
    maxNum: this.props.countrySet[0].maxNumPhone,
    country_id: 0,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    enableSocialRegister: false,
    disableButton: false
  };
  showLoader() {
    if (this.state.isLoading) {
      return <CustomLoader showLoader={this.state.isLoading} />;
    }
  }

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  onHandleVerifyClick = () => {
    if (
      this.state.enterotp === this.state.verificatonotp &&
      this.props.DRIVER_CONFIG_ROWS.register.email
    ) {
      console.log("1234567890987654321", this.state.driverEmail);
      this.props.getOtp(this.state.driverEmail);
    } else if (this.state.enterotp === this.state.verificatonotp) {
      this.props.getOtp(
        this.state.phonecode + this.state.phone_number,
        this.state.country_id,
        this.state.phonecode
      );
    } else {
      let res = {};
      res["result"] = 0;
      res["message"] = "OTP did not match!";
      this.props.otpCallback(res);
      //alert("Otp does not match");
    }
  };

  handleChange = async event => {
    let index = await this.state.country_code.findIndex(
      res => res.phonecode === event.target.value
    );

    this.setState({
      maxNum: this.state.country_code[index].maxNumPhone,
      phonecode: event.target.value,
      country_id: this.state.country_code[index].id
    });
  };

  onHandleClick = () => {
    // alert(PHONE_REGEX.test(this.state.phone_number))
    if (this.state.phone_number === "" && this.state.driverEmail === "") {
      this.setState({
        phoneMessage: this.props.emailMode
          ? "*Please enter your Email ID"
          : "*Please enter your Phone no."
      });
    } else {
      // if(PHONE_REGEX.test(this.state.phone_number)){
      this.setState({ isLoading: true });
      ApiManager.callPostApi(
        "DriverOtpVerification",
        "getotp APi",
        APPORIO_TAXI_APIS.DRIVER_OTP_GEN,
        {
          user_name: this.state.phone_number
            ? this.state.phonecode + this.state.phone_number
            : this.state.driverEmail,
          type: "1",
          for: this.props.DRIVER_CONFIG_ROWS.register.email ? "EMAIL" : "PHONE"
        }
      )
        .then(res => {
          if (res.result === "1") {
            this.props.otpCallback(res);
            this.setState({
              isLoading: false,
              isCheckOtp: true,
              verificatonotp: res.data.otp
              // showSnackbar: true,
              // snackbarVariant: 'success',
              // snakBarMessage: res.message
            });
          } else {
            this.props.otpCallback(res);

            this.setState({
              isLoading: false
              // showSnackbar: true,
              // snackbarVariant: 'error',
              // snakBarMessage: res.message
            });
          }
        })
        .catch(err => alert(JSON.stringify(err)));
      // }else{
      //   this.setState({
      //     phoneMessage:'*Please enter a valid Phone no.'
      //   })
      // }
    }
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "40vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />

        {this.showLoader()}
        <Typography
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "2%"
          }}
        >
          OTP Verification
        </Typography>
        {!this.props.emailMode &&
          !this.state.isCheckOtp && [
            <div style={{ display: "flex", padding: "0.5em" }}>
              <Select value={this.state.phonecode} onChange={this.handleChange}>
                {this.state.country_code.map((res, index) => (
                  <MenuItem key={index} value={res.phonecode}>
                    {res.login}
                  </MenuItem>
                ))}
              </Select>
              <input
                type="tel"
                // maxlength={this.props.countrySet[0].maxNumPhone}
                maxlength={this.state.maxNum}
                onFocus={() => this.setState({ phoneMessage: "" })}
                onChange={(event, value) => {
                  this.setState({ phone_number: event.target.value });
                }}
                placeholder="Enter Phone Number"
                style={{
                  fontSize: "0.8rem",
                  width: "100%",
                  borderBottom: "1px solid grey",
                  outline: "none"
                }}
              />
            </div>,
            <p style={{ fontSize: 12, color: "red" }}>
              {this.state.phoneMessage}
            </p>,
            <Button
              disabled={this.state.disableButton}
              onClick={() => this.onHandleClick()}
              style={{
                alignSelf: "center",
                marginTop: "5%",
                width: "70%",
                display: "flex",
                color: "black",
                fontSize: "0.8rem",
                fontWeight: "bold",
                backgroundColor: "#00649A"
              }}
            >
              Get Otp
            </Button>
          ]}

        {this.props.emailMode &&
          !this.state.isCheckOtp && [
            <div style={{ display: "flex", padding: "0.5em" }}>
              <input
                onFocus={() => this.setState({ phoneMessage: "" })}
                onChange={(event, value) => {
                  this.setState({ driverEmail: event.target.value });
                }}
                placeholder="Enter Your Email"
                style={{
                  fontSize: "0.8rem",
                  width: "100%",
                  borderBottom: "1px solid grey",
                  outline: "none"
                }}
              />
            </div>,
            <p style={{ fontSize: 12, color: "red" }}>
              {this.state.phoneMessage}
            </p>,
            <Button
              disabled={this.state.disableButton}
              onClick={() => this.onHandleClick()}
              style={{
                alignSelf: "center",
                marginTop: "5%",
                width: "70%",
                display: "flex",
                color: "black",
                fontSize: "0.8rem",
                fontWeight: "bold",
                backgroundColor: "#00649A"
              }}
            >
              Get Otp
            </Button>
          ]}

        {this.state.isCheckOtp && [
          <div style={{ display: "flex", padding: "0.5em" }}>
            <InputBase
              onChange={(event, value) => {
                this.setState({ enterotp: event.target.value });
              }}
              placeholder="Please enter Otp"
              style={{
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            />
          </div>,
          <Button
            onClick={() => this.onHandleVerifyClick()}
            style={{
              alignSelf: "center",
              marginTop: "5%",
              width: "70%",
              display: "flex",
              color: "black",
              fontSize: "0.8rem",
              fontWeight: "bold",
              backgroundColor: "#00649A"
            }}
          >
            Verify Otp
          </Button>
        ]}
      </div>
    );
  }
}

export default DriverOtpVerification;
